import React, {useEffect}  from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useLocation } from "react-router-dom";
import '@styles/pdp.scss';


const PdP = () => {
    const location = useLocation();
    useEffect(() => {
        window.dataLayer.push({
            event: "page_view"
        })
    }, [location]);

	return (
        <div className="pdp">
            <header className="header"><Header/></header>
            <div className='tycContainer'>
                <div className='tycTitle'>Política de privacidad</div>
                <div className='tyctext'>
                    <p>
                    ¡Bienvenido a Firullife! Nos tomamos muy en serio la privacidad y seguridad de tus datos personales. Estamos comprometidos a proteger la
                    privacidad y la confianza de nuestros usuarios y a cumplir con todas las leyes y regulaciones aplicables relacionadas con la privacidad de los
                    datos.
                    </p>
                    <p>
                        Nuestras políticas de privacidad están diseñadas para explicarte cómo recopilamos, utilizamos, compartimos y protegemos la información
                        personal que nos proporcionas. Creemos que es importante que sepas qué información recopilamos, por qué la recopilamos y cómo la utilizamos.
                        En Firulife, comprendemos la importancia de la privacidad en línea y nos esforzamos por mantener la confianza de nuestros usuarios al proteger
                        su información personal contra el acceso no autorizado, la divulgación, la alteración o la destrucción.
                    </p>
                    <p className='tycSubTitle'>1. Recopilación y utilización de su información</p>
                    <p>
                        Esta Política de Privacidad contempla la recopilación y uso de información personal en el Sitio Web. Firulife no recopilará a través del Sitio
                        Web ninguna información sobre usted que pueda identificarlo personalmente, como, por ejemplo, su nombre, dirección, número de teléfono o
                        dirección de correo electrónico (en adelante, la “Información Personal”), a menos que usted nos la brinde en forma voluntaria. Si usted no desea
                        que recopilemos su Información Personal, por favor absténgase de proporcionarla. Sin embargo, deberá tener en cuenta que en caso de no
                        proporcionarnos su Información Personal, no podrá acceder a los servicios e información disponibles a través del Sitio Web. En caso de que usted
                        nos brinde Información Personal, le comunicamos que esa Información Personal será objeto de tratamiento automatizado e incorporada a la base de
                        datos de Firulife.
                    </p>
                    <p>
                        La Información Personal que usted suministre será reputada y tratada como confidencial. Sin perjuicio de lo expuesto, Firulife podrá
                        compartir total o parcialmente la Información Personal suministrada por usted, incluyendo registros de comportamiento y actividad en el Sitio,
                        contactos a terceros que anuncien u ofrezcan productos o servicios en el Sitio Web, parámetros de interés de búsquedas así como su historial
                        de búsqueda y preferencias en Firulife o a terceros.
                    </p>
                    <p>
                        Asimismo, en el evento de vender todo o parte del negocio de Firulife, usted autoriza a Firulife a transferir al comprador las bases de datos
                        que contiene su Información Personal. De ser el caso, se le comunicará de este hecho oportunamente.
                    </p>
                    <p className='tycSubTitle'>2. Información personal</p>
                    <p>
                        Tu currículum vitae documentado incluyendo la información de tus datos
                        personales solo se gestiona o almacena en nuestra base de datos cuando
                        te pones en contacto con Contrata a través de nuestros canales
                        digitales y manifiestas como titular tu consentimiento expreso.
                    </p>
                    <p className='tycSubTitle'>3. Selección y exclusión</p>
                    <p>Firulife recopila Información Personal en línea cuando, entre otros casos:</p>
                    <p>- Usted se registra para hacer uso de alguno de los servicios disponibles del Sitio Web.</p>
                    <p>- Usted utiliza el Sitio Web.</p>
                    <p>- Usted nos envía preguntas, consultas o comentarios o se contacta con el Sitio Web.</p>
                    <p>- Usted solicita información o materiales.</p>
                    <p>- Usted provee de cualquier forma información al Sitio Web (chats, foros, uploads, etc.).</p>
                    <br/>
                    <p>Su Información Personal podrá ser tratada por Firulife, sus empresas relacionadas y aliados comerciales directamente o a través de sus proveedores, para:</p>
                    <p>* Efectuar las gestiones pertinentes para el desarrollo del objeto social de la compañía en lo que tiene que ver con el cumplimiento del objeto del contrato celebrado con usted.</p>
                    <p>* Dar cumplimiento a obligaciones contraídas con usted.</p>
                    <p>* Preparar, implementar, promocionar y ofrecer nuevos productos y servicios, o bien, nuevos atributos, modalidades o características a los productos y servicios que ya están a su disposición.</p>
                    <p>* Ajustar la oferta de productos y servicios de Firulife y sus empresas relacionadas a su perfil de cliente, o bien, efectuar análisis, reportes y evaluaciones a su respecto.</p>
                    <p>* De contar con su consentimiento, desarrollar acciones comerciales, de carácter general o dirigidas personalmente a usted, tendientes a mejorar su experiencia como usuario del Sitio Web.</p>
                    <p>* Compartir la Información Personal a terceros a efectos de protección contra el fraude y la reducción de riesgo de crédito.</p>
                    <p>* Transferir la Información Personal fuera o dentro del país a las empresas relacionadas para que estas puedan tratar su Información Personal de acuerdo a las finalidades consagradas en esta Política de Privacidad, de conformidad con las finalidades aquí señaladas.</p>
                    <br/>
                    <p>
                        El tipo de información recopilada puede incluir nombre, tipo y número de documento, teléfono, dirección de correo electrónico y/o cualquier
                        otra información que permita individualizarlo. En todos los casos que usted brinde Información Personal, y de acuerdo a la legislación
                        vigente, usted declara que la Información Personal brindada es correcta, cierta y actual.
                    </p>
                    <p>
                        En los casos que nos brinde su Información Personal, usted acepta y presta su consentimiento libre, previo, expreso e informado para que
                        dicha Información Personal sea utilizada con las finalidades arriba mencionadas y autoriza a que la misma sea tratada, almacenada, recopilada
                        en la base de datos USUARIOS DE PLATAFORMAS WEB Y MOVIL propiedad de Firulife, registrado mediante Resolución N° 3111-2016-JUS/DGPDP-DRN
                        y código de inscripción RNPDP-PJP N° 11156. En tal sentido, usted autoriza a que su Información Personal sea compartida con los clientes de
                        Firulife, los cuales se encuentran a la vista en nuestro Sitio Web. Asimismo, usted acepta y presta su consentimiento libre,
                        previo, expreso e informado con los términos de la presente Política de Privacidad.
                    </p>
                    <p>
                        Firulife almacenará los datos personales de sus usuarios hasta por un máximo de 20 años, o hasta que el usuario dé de baja su cuenta en
                        nuestro Portal; o si el usuario hace uso de su derecho de cancelación o supresión, lo que ocurra primero. Luego de ello, sus datos serán
                        eliminados.
                    </p>
                    <p className='tycSubTitle'>4. Subscripción promocionales</p>
                    <p>
                        Firulife podrá enviarle correos electrónicos en relación con el contenido del Sitio Web, los servicios prestados por Firulife o sobre su cuenta
                        y en respuesta a sus preguntas, pedidos, consultas o comentarios. Firulife también, de contar con su consentimiento, le podrá enviar correos
                        electrónicos con información sobre productos y servicios ofrecidos por Firulife y/o terceros asociados comercialmente que le puedan resultar
                        de interés, a menos que usted indique expresamente que no desea recibir dichos correos electrónicos a través de los procesos implementados por
                        Firulife a tal efecto. odo correo electrónico que reciba de Firulife le informará cómo rechazar el envío de futuros correos electrónicos
                        promocionales. Asimismo, usted podrá cambiar sus preferencias de recepción de correos electrónicos publicitarios a través de la configuración
                        de su cuenta en el propio Sitio Web en cualquier momento.
                    </p>
                    <p className='tycSubTitle'>5. Trazabilidad en la plataforma</p>
                    <p>
                        Firulife puede hacer uso de cookies para recopilar información para mostrar anuncios, supervisar el rendimiento de la web a medida que el usuario interactua con el,
                        garantinzar el correcto funcionamiento del sitio web, así como entender el comportamiento del usuario en la plataforma.
                    </p>
                    <p className='tycSubTitle'>6. Confidencialidad</p>
                    <p>
                        <b>Firulife</b> se compromete a guardar reserva y confidencialidad de la información proporcionada por los Usuarios e información que se
                        almacene en nuestro banco de datos en estricto cumplimiento de la Ley Nº 29733, Ley de Protección de Datos Personales o aquellos que se
                        reciba pornuestros canales digitales conforme a nuestro Términos y Condiciones.
                    </p>
                    <p>
                        Para prevenir acceso no autorizado, mantener la precisión de los datos y asegurar el uso correcto de su Información Personal,
                        Firulife ha puesto en uso ciertos medios físicos, electrónicos, administrativos y procedimientos de seguridad para resguardar y
                        asegurar la Información Personal que recopilamos en línea. Nosotros resguardamos la Información Personal de acuerdo a estándares y
                        procedimientos de seguridad establecidos y continuamente evaluamos nueva tecnología para proteger esa información. Firulife garantiza que
                        los procesos internos propios de las bases de datos cumplen con las obligaciones legales de seguridad y confidencialidad impuestas por las
                        leyes de cada país en materia de privacidad y protección de datos personales.
                    </p>
                    <p>
                        A pesar de lo anterior, usted reconoce que los medios técnicos existentes que brindan seguridad no son inexpugnables y que aun cuando se
                        adopten todos los recaudos razonables de seguridad es posible sufrir manipulaciones, destrucción y/o pérdida de información. De presentarse
                        estos casos, Firulife procederá conforme las leyes de cada país en materia de privacidad y protección de datos lo requieran.
                    </p>
                    <p>
                        Los empleados de Firulife son entrenados para comprender y cumplir con estos principios en materia de protección de datos personales
                        y seguridad de la información. Asimismo, los empleados de Firulife asumen estrictos compromisos de confidencialidad en cuanto a la
                        Información Personal que procesan en el ejercicio de sus funciones.
                    </p>
                    <p>
                        Usted consiente, reconoce y acepta que su información personal sea almacenada en la jurisdicción de Firulife o que la misma pueda ser
                        transferida, almacenada y tratada fuera de su país de residencia.
                    </p>
                    <p className='tycSubTitle'>7. Uso de la aplicación en menores de edad</p>
                    <p>
                        Firulifer no tiene intenciones de recopilar Información Personal de menores de edad. Cuando corresponda, Firulifer le indicará
                        específicamente a los menores que no brinden esa información Personal en nuestro Sitio Web y/o tomará medidas razonables para
                        obtener el consentimiento de los padres, tutor o representante legal para la entrega de esa Información Personal. Le informamos que en
                        su condición de padre, tutor legal o representante será el responsable de que sus hijos menores o bajo su tutela accedan al Sitio Web,
                        por lo que recomendamos enfáticamente tomar las precauciones oportunas durante la navegación en el Sitio Web. A este fin, le informamos
                        que algunos navegadores permiten configurarse para que los niños no puedan acceder a páginas determinadas.
                    </p>
                    <p className='tycSubTitle'>8. Vínculos a otros sitios</p>
                    <p>
                        El Sitio Web puede contener links hacia y provenientes de otros sitios de Internet. Firulife no es responsable por las prácticas
                        de privacidad ni el tratamiento de los datos personales de esos sitios. Firulife recomienda que consulten las prácticas de
                        privacidad de dichos sitios de Internet antes de su utilización.
                    </p>
                    <p className='tycSubTitle'>8. Derechos del usuario</p>
                    <p>
                        Si usted ha proporcionado Información Personal a través de los servicios disponibles en el Sitio Web, usted podrá acceder a la misma,
                        revisar, modificar, eliminar y actualizar su Información Personal en el momento que lo desee. Si usted desea acceder a su Información
                        Personal o si tal Información Personales es incorrecta, desactualizada y/o suprimida, por favor tenga a bien enviar su solicitud por
                        correo electrónico asunto “Informar”, “Rectificar”, “Suprimir” y/o “Actualizar” y/o cualquier otro según corresponda, conjuntamente con el
                        objeto de su requerimiento. Asimismo, si usted se suscribe a alguno de los servicios o comunicaciones que ofrece Firulife podrá
                        dar de baja su suscripción en cualquier momento siguiendo las instrucciones incluidas en cada comunicación. Firulife cooperará
                        con las autoridades de cada país cuando éstas requieran formalmente cualquier información relacionada con nuestras bases de datos.
                    </p>
                    <p className='tycSubTitle'>11. Duración</p>
                    <p>
                        Firulife se reserva el derecho a modificar esta Política de Privacidad periódicamente, en cuyo caso la política actualizada se
                        publicará en este mismo sitio, siendo obligación de los usuarios revisar regularmente esta sección a fin de informarse de cualquier cambio
                        que se pueda haber producido. De todos modos, Firulife cursará una comunicación a su cuenta de correo electrónico registrada a
                        efectos de informarle sobre el cambio en la Política de Privacidad.
                    </p>
                    <p className='tycSubTitle'>12. Firulife</p>
                    <p>
                        La denominación legal que adopta Firulife es: LECCRU EIRL, con RUC Nº 20601518105 y domicilio en Calle. Urb. Vruces mz D Lt 65, Nuevo Chimbote, Ancash, Perú. El correo electrónico para
                        recbar, recibir y verificar información, solicitudes y otros es firufirulife@gmail.com
                    </p>
                    <br/>
                    <br/>
                    <br/>
                    <p>
                        Esta Política de Privacidad fue actualizada por última vez el 31 de marzo de 2024.
                    </p>
                </div>
            </div>
            <Footer/>
        </div>
	);
}

export default PdP;